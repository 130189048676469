// src/components/Footer.js
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/css"
import React from 'react';

function Footer() {
  return (
    <footer className={footerStyle}>
       <p>צרו עימנו קשר במייל: barpinian1213@gmail.com</p>
       <p>&copy; 2024 Bar Motors Import. All rights reserved.</p>
    </footer>
  );
}

const footerStyle = css`
  background-color: #333;
  color: white;
  padding: 10px;
  text-align: center;
  width: 100%;
`;

export default Footer;
