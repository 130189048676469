import React from 'react';
import { css } from "@emotion/css"

function Home() {
    const cars = [
        { id: 1, image: `${process.env.PUBLIC_URL}/carsImage/eqs.avif` },
        { id: 2, image: `${process.env.PUBLIC_URL}/carsImage/Hummer-EV-SUV.jpg` },
        { id: 3, image: `${process.env.PUBLIC_URL}/carsImage/hummer.webp` },
        { id: 4, image: `${process.env.PUBLIC_URL}/carsImage/machE.webp` },
        { id: 5, image: `${process.env.PUBLIC_URL}/carsImage/q4.jpg` },
        { id: 6, image: `${process.env.PUBLIC_URL}/carsImage/silverado-ev.jpg` },
        { id: 7, image: `${process.env.PUBLIC_URL}/carsImage/sq8.jpg` },
      ];

  return (
    <div className={homeStyle}>
      <h2>ברוכים הבאים ל BarMotorsImport הדרך שלך להגשים חלום</h2>
      <p>הנה כמה מהאפשרויות לייבוא לפניך:</p>

      <div className={galleryStyle}>
        {cars.map(car => (
          <div key={car.id} className={carContainerStyle}>
            <img src={car.image} alt={car.name} className={carImageStyle} />
            <p>{car.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

// Emotion CSS
const homeStyle = css`
  padding: 20px;
  text-align: center;
`;

const galleryStyle = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const carContainerStyle = css`
  text-align: center;
`;

const carImageStyle = css`
  width: 100% !important;
  height: 250px !important;
  object-fit: cover !important;
  border-radius: 8px !important;
`;

export default Home;
