/** @jsxImportSource @emotion/react */
import { css } from "@emotion/css";
import React, { useState } from "react";
import axios from "axios"; // Ensure axios is installed

const apiUrl = process.env.REACT_APP_API_URL;

function CarsInformation() {
  const [carNumber, setCarNumber] = useState("");
  const [carData, setCarData] = useState(null);
  const [error, setError] = useState("");

  const handleSearch = async () => {
    if (!carNumber) {
      setError("אנא הזן מספר רכב");
      return;
    }
    debugger;
    setError(""); // Clear previous errors
    try {
      const response = await axios.get(`${apiUrl}/car-information/${carNumber}`);
      console.log("response", response)
      setCarData(response.data); // Update the state with API response
      console.log("Car Data:", response.data);
    } catch (err) {
      console.error("Error fetching car information:", err);
      setError("אירעה שגיאה בקבלת המידע");
    }
  };

  return (
    <div className={containerStyle}>
      <h2>חיפוש מידע על רכב</h2>
      <div className={inputContainerStyle}>
        <label htmlFor="carNumber">מספר רכב:</label>
        <input
          type="number"
          id="carNumber"
          value={carNumber}
          onChange={(e) => setCarNumber(e.target.value)}
          className={inputStyle}
        />
      </div>
      <button onClick={handleSearch} className={buttonStyle}>
        חפש
      </button>
      {error && <p className={errorStyle}>{error}</p>}
      {carData && (
        <div className={resultStyle}>
          <h3>תוצאות חיפוש:</h3>
          <pre>{JSON.stringify(carData, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}

// Emotion CSS
const containerStyle = css`
  padding: 20px;
  text-align: center;
  direction: rtl; /* Ensures RTL text alignment */
`;

const inputContainerStyle = css`
  margin: 20px 0;
`;

const inputStyle = css`
  margin-left: 10px;
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const buttonStyle = css`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;

const errorStyle = css`
  color: red;
  margin-top: 10px;
`;

const resultStyle = css`
  margin-top: 20px;
  text-align: left;
  direction: rtl;
`;

export default CarsInformation;
