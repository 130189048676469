import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Cars from './pages/CarsInformation';
import Contact from './pages/Contact';
import ImportCalculator from './pages/ImportCalculator'; // Import the new page
import CarsInformation from './pages/CarsInformation';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="home" element={<Home />} />
          <Route path="/cars" element={<Cars />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/import-calculator" element={<ImportCalculator />} />
          <Route path="/cars-information" element={<CarsInformation />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;