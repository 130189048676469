// src/pages/ImportCalculator.js
import React, { useState } from 'react';
import { css } from "@emotion/css"

function ImportCalculator() {
  // State variables to handle the input values
  const [carPrice, setCarPrice] = useState('');
  const [taxRate, setTaxRate] = useState('');
  const [shippingCost, setShippingCost] = useState('');
  
  // This is where you'll calculate the import fee based on inputs (you can expand this)
  const calculateImportFee = () => {
    const price = parseFloat(carPrice) || 0;
    const tax = parseFloat(taxRate) || 0;
    const shipping = parseFloat(shippingCost) || 0;
    const importFee = price * (tax / 100) + shipping;
    return importFee.toFixed(2); // Example fee calculation
  };

  return (
    <div className={calculatorStyle}>
      <h2>מחשבון ייבוא רכב (car import Fee Calculator)</h2>
      <p>Here you can calculate the import fees for your vehicle.</p>

      {/* Input for car price */}
      <div className={inputContainerStyle}>
        <label>מחיר הרכב (Car Price):</label>
        <input
          type="number"
          value={carPrice}
          onChange={(e) => setCarPrice(e.target.value)}
          placeholder="Enter car price"
        />
      </div>

      {/* Input for tax rate */}
      <div className={inputContainerStyle}>
        <label>אחוז מס (Tax Rate %):</label>
        <input
          type="number"
          value={taxRate}
          onChange={(e) => setTaxRate(e.target.value)}
          placeholder="Enter tax rate"
        />
      </div>

      {/* Input for shipping cost */}
      <div className={inputContainerStyle}>
        <label>עלות משלוח (Shipping Cost):</label>
        <input
          type="number"
          value={shippingCost}
          onChange={(e) => setShippingCost(e.target.value)}
          placeholder="Enter shipping cost"
        />
      </div>

      {/* Display the calculated import fee */}
      <div className={resultStyle}>
        <p>עלות כוללת (Total Import Fee): <strong>{calculateImportFee()} ש"ח</strong></p>
      </div>
    </div>
  );
}

// Emotion CSS for the page
const calculatorStyle = css`
  padding: 20px;
  text-align: center;
`;

const inputContainerStyle = css`
  margin-bottom: 20px;
  text-align: right;

  label {
    margin-right: 10px;
    font-size: 1.2rem;
  }

  input {
    width: 200px;
    padding: 8px;
    font-size: 1rem;
  }
`;

const resultStyle = css`
  margin-top: 20px;
  font-size: 1.5rem;
  color: green;
`;

export default ImportCalculator;
