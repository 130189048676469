// src/pages/Contact.js
import React from 'react';
import { css } from "@emotion/css"

function Contact() {
  return (
    <div className={contactStyle}>
      <h2>Contact Us</h2>
      <p>If you have any questions, feel free to contact us!</p>
      <p>Email: info@barmotorsimport.com</p>
      <p>Phone: +123 456 7890</p>
    </div>
  );
}

// Emotion CSS
const contactStyle = css`
  padding: 20px;
  text-align: center;
`;

export default Contact;
