/** @jsxImportSource @emotion/react */
import { css } from "@emotion/css"
import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header className={headerStyle}>
      <div className={logoContainerStyle}>
        <img src="/logo.png" alt="Bar Motors Import" className={logoStyle} />
        <h1>Bar Motors Import</h1>
      </div>
      <nav>
        <ul className={navListStyle}>
          <li><Link to="/home" className={navLinkStyle}>דף הבית</Link></li>
          <li><Link to="/cars-information" className={navLinkStyle}>מידע על רכב לפי מספר</Link></li>
          <li><Link to="/contact" className={navLinkStyle}>צור קשר</Link></li>
          <li><Link to="/import-calculator" className={navLinkStyle}>מחשבון ייבוא</Link></li>
        </ul>
      </nav>
    </header>
  );
}

const headerStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #333;
  color: white;
  direction: rtl;  /* Set RTL */
`;

const logoContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;  /* Align logo and text to the right */
`;

const logoStyle = css`
  width: 100px;
  height: auto;
  margin-left: 15px;  /* Adjust margin for RTL */
`;

const navListStyle = css`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
`;

const navLinkStyle = css`
  color: white;
  text-decoration: none;
  margin: 0 15px;

  &:hover {
    text-decoration: underline;
  }
`;

export default Header;
